export const fetchUsers = async (GET, paging, search) => {
    const query = { page_num: paging.page, page_size: paging.pageSize, query: search };
    const { data } = await GET('/api/users', null, { query });

    return data;
};

export const fetchUser = async (GET, id) => {
    const { data } = await GET(`/api/users/${id}`);

    return data;
};

export const fetchJoinedActivities = async (GET, id, paging) => {
    const query = { page_num: paging.page, page_size: paging.pageSize };
    const { data } = await GET(`/api/users/${id}/activities`, null, { query });

    return data;
};

export const refund = async (POST, outTradeNo, msg) => {
    const { data } = await POST(`/api/payment/outtradeno/refund`, { out_trade_no: outTradeNo, refund_msg: msg });

    return data;
};
